.main{
    text-align: center;
align-items: center;
justify-content: center;
   justify-items: center;
}
.gracias{
    font-size: large;
    color: cornflowerblue;
    width: 50%;
    text-align: center;
}

@media (max-width: 575.98px) {
   .gracias{
    width: 90%;
   }
    
}