.App {
  text-align: center;
}
.App-home{
  margin-bottom: 10px;
}
.App-home-favoritas{
  margin-bottom: 40px;
  margin-top: -40px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color:#555555;
  color: white;
  box-shadow: 5px 10px 15px black

}


.App-PlayButton{
 
    cursor: pointer;
    display: inline-flex;
    padding: 10px;
    margin-top: 0px;
    margin-bottom: 10px;
    border-radius: 40px;
    background-color: #444444;
  
   
    box-shadow: 5px 10px 15px black ;
              
   
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}
input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}
input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #000000;
  height: 26px;
  width: 10px;
  border-radius: 3px;
  background: #222222;
  cursor: pointer;
  margin-top: -10px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
  border-radius: 40px;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  border-radius: 40px;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #444444, 0px 0px 1px #444444;
  border: 1px solid #444444;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #444444, 0px 0px 1px #444444;
  background: #444444;
  border-radius: 13px;
  border: 0.2px solid #444444;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #444444;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #444444, 0px 0px 1px #444444;
  background: #444444;
  border-radius: 1.3px;
  border: 0.2px solid #444444;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #444444;
  border: 0.2px solid #444444;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #444444, 0px 0px 1px #444444;
}
input[type=range]:focus::-ms-fill-lower {
  background: #444444;
}
input[type=range]::-ms-fill-upper {
  background: #444444;
  border: 0.2px solid #444444;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #444444, 0px 0px 1px #444444;
}
input[type=range]:focus::-ms-fill-upper {
  background: #444444;
}
  .App-banner {
    background-color: #555555;
    color: white;
    box-shadow: 5px 10px 15px black ;
    margin-bottom: 10px;
    
    
  }
  .App-rep{
    background-color: #555555;
    color: white;
  }

  .App-header {
    color: white;
    background:  rgb(44, 44, 44) ;
  }

  .App-CardRadio {
   
    background:#3f3f3f ;
    box-shadow: 5px 10px 15px black
  }
  .App-CardRadio-Favoritas{
   
    background:#3f3f3f ;
  }

  
  


.App-banner {

  position: sticky;
  top: 0px;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: inline-flex;
  align-items: bottom;
  justify-content: center;
 
  z-index: 100;
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);

}

.App-footer {
  background-color: #222222;
  color: white;
  font-size: medium;

}

.App-link {
  color: #61dafb;
}

.App-CardRadio {
 
  border-radius: 20px;
  padding: 10px;
  margin:5px;
  display:inline-table;

}
.App-CardRadio-Favoritas {
 
  border-radius: 10px;
  padding: 5px;
  margin:15px;
  display:inline-table;
  width: 70px;
  box-shadow: 5px 10px 15px black


}

.App-Reproductor {
  display: inline-flex;
  margin: 5px;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}